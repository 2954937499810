<template>
    <div class="ml-10 mr-10">
        <TitleBar :loading="loading" :text="this.$t('company.settings.expense-reports')"></TitleBar>

        <v-form @submit.prevent="formSubmit">

            <v-text-field
                v-model="expense_report_url"
                :disabled="loading"
                :label="$t('company.expense_report.url')"
                outlined
                type="url"
                @input="debouncedVerify"
            ></v-text-field>

            <v-card v-if="expense_report_url" :loading="loading_verify" class="mb-5" outlined>
                <v-card-text>
                    <p v-html="$t('company.expense_report.cname', {'cname': 'custom-domain.accounting.sh'})"></p>

                    <v-row v-if="verify_loaded" class="mt-3">
                        <v-col md="6">
                            <p v-if="!domain_taken" class="mb-0">
                                <v-icon class="green--text">mdi-check-circle-outline</v-icon>
                                {{ $t("company.expense_report.domain_available") }}
                            </p>

                            <p v-else class="mb-0">
                                <v-icon class="red--text">mdi-close-circle-outline</v-icon>
                                {{ $t("company.expense_report.domain_taken") }}
                            </p>
                        </v-col>
                        <v-col md="6">
                            <p v-if="dns_working" class="mb-0">
                                <v-icon class="green--text">mdi-check-circle-outline</v-icon>
                                {{ $t("company.expense_report.dns_working") }}
                            </p>
                            <p v-else class="mb-0">
                                <v-icon class="red--text">mdi-close-circle-outline</v-icon>
                                {{ $t("company.expense_report.dns_not_working") }}
                            </p>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

            <v-text-field
                v-model="expense_report_email_rule"
                :disabled="loading"
                :hint="$t('company.expense_report.email_rule_hint')"
                :label="$t('company.expense_report.email_rule')"
                outlined
                persistent-hint
            ></v-text-field>

            <v-select
                v-model="expense_report_oauth_services"
                :disabled="loading"
                :items="oauth_services"
                :label="$t('company.expense_report.oauth_services')"
                chips
                class="mt-5"
                clearable

                hide-details
                multiple
                outlined
            ></v-select>

            <v-switch
                color="secondary"
                v-model="expense_report_email_login"
                :label="$t('company.expense_report.email_login')"
            ></v-switch>

            <v-btn
                :loading="loading"
                class="mt-2"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $t("company.expense_report.update") }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import Settings from "@/helpers/Settings";
import Accounting from "@/helpers/Accounting";
import _ from "lodash";

export default {
    name: "Customisation",
    components: {TitleBar},
    created() {
        this.debouncedVerify = _.debounce(this.verify, 1000);
    },
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.expense-reports"),
                to: "/company/settings/expense-reports",
                exact: true
            }
        ]);

        this.settings = new Settings();

        this.loading = true;

        await Accounting.get("/oauth?fields=uuid,provider,client_id&per_page=-1").then(resp => {
            this.oauth_services = [];

            for (const configElement of resp.data.config) {
                this.oauth_services.push({
                    value: configElement.uuid,
                    text: configElement.provider + " (" + configElement.client_id + ")"
                });
            }

        }).catch(() => {
        });

        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/expense_report~url",
            value => {
                this.expense_report_url = value;
                if (value !== null) {
                    this.verify();
                }
            },
            () => this.expense_report_url
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/expense_report~email_validation",
            value => {
                this.expense_report_email_rule = value;
            },
            () => this.expense_report_email_rule
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/expense_report~oauth_services",
            value => {
                this.expense_report_oauth_services = value;
            },
            () => this.expense_report_oauth_services
        );
        this.settings.addSetting(
            "/companies/" + this.$store.state.company.uuid + "/settings/expense_report~email_login",
            value => {
                this.expense_report_email_login = value === "true";
            },
            () => this.expense_report_email_login ? "true" : null
        );

        this.settings.get()
            .finally(() => {
                this.loading = false;
            });
    },
    data() {
        return {
            loading: false,
            expense_report_url: null,
            expense_report_email_rule: null,
            expense_report_email_login: true,
            expense_report_oauth_services: [],
            settings: null,
            oauth_services: [],

            domain_taken: false,
            dns_working: false,
            loading_verify: false,
            verify_loaded: false

        };
    },
    methods: {
        verify() {
            this.loading_verify = true;
            Accounting.post("/expenses/expense-reports/verify", {
                domain: this.expense_report_url
            }).then(resp => {
                const data = resp.data;
                this.domain_taken = !data.available;
                if (data.cname !== undefined) {
                    this.dns_working = data.cname;
                }
                this.verify_loaded = true;
            }).catch(() => {
            }).finally(() => {
                this.loading_verify = false;
            });
        },
        formSubmit() {
            this.loading = true;
            this.settings.update()
                .finally(() => {
                    this.loading = false;
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("company.updated")
                    }]);
                });
        }
    }
};
</script>

<style scoped>

</style>