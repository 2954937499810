import Accounting from "@/helpers/Accounting";

export default class Settings {
    settings = [];

    addSetting(url, callback, value) {
        this.settings.push({
            url: url,
            callback: callback,
            value: value
        });
    }

    get() {
        return new Promise(resolve => {
            let promiseArr = [];

            for (const setting of this.settings) {
                promiseArr.push(Accounting.get(setting.url).then(resp => {
                    setting.callback(resp.data.value);
                }).catch(() => {
                }));
            }

            Promise.all(promiseArr)
                .finally(() => {
                    resolve();
                });
        });

    }

    update() {
        return new Promise(resolve => {
            let promiseArr = [];

            for (const setting of this.settings) {
                promiseArr.push(Accounting.post(setting.url, {
                    value: setting.value()
                }).catch(() => {
                }));
            }

            Promise.all(promiseArr)
                .finally(() => {
                    resolve();
                });
        });

    }

}